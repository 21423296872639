import React, { useEffect, useState } from 'react'
import styles from "./detailsTwo.module.css"
import { NavLink, useSearchParams } from 'react-router-dom'
import { Button, Toast } from 'react-bootstrap'
import axios from 'axios'

function DetailsTwo() {
    const [age, setAge] = useState()
    const [gender, setGender] = useState("")
    const [searchParams] = useSearchParams();
    let mode = searchParams.get("mode")
    let hash = searchParams.get("hash")
    let uid = searchParams.get("uid")
    const [error, setError] = useState("")

    useEffect(() => {
        if (!mode && !hash && !uid) {
            window.location.href = "/"
        }
        console.log(mode, hash, uid)
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!age) { alert("Age is required"); return; }
        if (!gender) { alert("Gender is required"); return; }
        setError("")
        try {
            let allocationStatus = await axios.get(`${process.env.REACT_APP_API_AUTH_URL}/api/allocation-status/${hash}`)

            if(allocationStatus.data.data.allocationStatus) alert("Allocation is completed")

            let response = await axios.post(`${process.env.REACT_APP_API_AUTH_URL}/api/survey`, {
                uid: uid,
                age: age,
                gender: gender,
                mode: mode,
                hash: hash
            })
            window.location.href = response.data.link
        } catch (error) {
            if(error.response.status===300)
            {
                window.location.href = `${process.env.REACT_APP_API_AUTH_URL}/api/render-complete-page?status=${error.response.data.status}`
            }
            setError(error.response.data)
        }
    }

    return (
        <div className={styles["main-container"]}>
            <div
            className='side-nav-logo-last-text'
            onClick={() => {
                window.open("https://calcinsights.com","_target")
            }} style={{
            position: "absolute",
                top: "50px",
                right: "30px",
                fontSize: "24px",
                cursor: "pointer"
            }}>Home</div>
            <div className={styles["container"]}>
                <NavLink to="/">
                    <span className="side-nav-logo-first-text" style={{
                        color: "#000"
                    }}>CALC</span>
                    <span className="side-nav-logo-last-text">INSIGHTS</span>
                </NavLink>
                <p><strong>Greetings! A warn welcome to you!</strong></p>

                <form className={styles["form-lp"]} onSubmit={(e) => {
                    handleSubmit(e)
                }}>
                    <h4> What is your age?</h4>
                    <input
                        className={styles["input-age"]}
                        placeholder="Age"
                        type="number"
                        value={age}
                        onChange={(e) => { setAge(e.target.value) }}
                    />

                    <h4 style={{ marginTop: "20px" }}> What is your gender?</h4>
                    <select className={styles["input-age"]} value={gender} onChange={(e) => { setGender(e.target.value) }}>
                        <option value="" selected disabled>Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                    </select>
                    <br></br>

                    <Button type="submit" style={{ marginTop: "10px", padding:"0px" }}><span style={{padding:"0px 20px"}}>Agree</span></Button>
                    {
                        error && (
                            <p style={{
                                fontSize: "12px"
                            }} dangerouslySetInnerHTML={{ __html: error }}></p>
                        )
                    }
                </form>


            </div>
        </div >
    )
}

export default DetailsTwo